@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.regen-entity {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: $neutral0;
  border: 1px solid $neutral1;
  border-radius: 8px;
  row-gap: 12px;

  &__title {
    @include description-regular($neutral5);
    font-weight: 700;
  }

  &__add-custom.ant-btn.cb-button {
    width: fit-content;
    text-transform: inherit;
    letter-spacing: 0;
    padding: 5px 0;
    color: $blue3;

    &:disabled {
      background-color: transparent !important;
      border: none !important;
    }

    span  {
      text-decoration: underline;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    &-label {
      @include description-regular($neutral3);
      margin-bottom: 8px;
    }
  }
}
