@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cs-element-main-view {
  display: flex;
  flex-direction: column;
  padding: 8px 4px 8px 24px;
  row-gap: 8px;

  .cb-input {
    padding: 0 11px 0 0;
    background-color: transparent;
  }

  &__inner {
    display: flex;
  }

  &__info {
    display: flex;
    align-items: center;
    width: 96px;
    min-width: 96px;
    height: 24px;
    padding-left: 28px;
    margin-right: 4px;

    [dir='rtl'] & {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: 8px;
    width: 104px;
    min-width: 104px;
    column-gap: 4px;
    row-gap: 4px;
    padding-top: 3px;
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 16px;
    height: 24px;
    margin-right: 8px;

    .ccm-svg-inline {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__count {
    @include footnote($neutral3);

    &-wrap {
      display: flex;
      width: 56px;
      min-width: 56px;
      margin-right: 8px;
    }
  }

  &__title {
    width: 100%;
    @include body-regular($neutral5);
    padding: 0 4px;
    background-color: transparent;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-top: 0;

    &:disabled {
      color: $neutral2 !important;

      .cs-collapse--disabled & {
        background-color: transparent;
        color: $neutral5 !important;

        &:hover {
          cursor: auto;
        }
      }
    }

    &:hover {
      background-color: $neutral1;
    }
  }

  &__description {
    width: 100%;
    @include description-regular($neutral4);
    padding: 0 4px;
    background-color: transparent;
    white-space: pre-wrap;
    word-wrap: break-word;

    &:disabled {
      color: $neutral2 !important;

      .cs-collapse--disabled & {
        background-color: transparent;
        color: $neutral5 !important;

        &:hover {
          cursor: auto;
        }
      }
    }

    &:hover {
      background-color: $neutral1;
    }
  }

  &__text {
    @include description-regular($neutral4);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 4px;
    height: 24px;
    width: 108px;
    min-width: 108px;
    margin-left: 8px;
    padding-right: 28px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 8px;
      padding-right: 0;
      padding-left: 28px;
    }

    &--hidden {
      width: 24px;
      min-width: 24px;
    }
  }

  .ant-btn.cb-button.item-type-select__button {
    background-color: transparent !important;
  }

  &--disabled {
    .item-type-select__button {
      background-color: transparent !important;

      path {
        fill: $neutral2;
      }
    }
  }

  &--public {
    .item-type-select__button {
      background-color: transparent !important;

      path {
        fill: $neutral5;
      }
    }
  }
}
