@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.setting-switch-block {
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 16px;
  background-color: $neutral0;
  border: 1px solid $neutral1;
  border-radius: 8px;
  column-gap: 12px;

  .ant-switch {
    margin-bottom: 16px;
  }

  &--long {
    flex-direction: row;
    width: 100%;
    height: auto;

    .ant-switch {
      margin-top: 6px;
      margin-bottom: 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &-beta {
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 42px;
    height: 20px;

    &__label {
      @include footnote($neutral5)
    }
  }

  .ant-switch {
    width: 28px;
    min-height: 16px;
  }

  &__label {
    @include body-regular($neutral5);
    margin-bottom: 4px;
  }

  &__text {
    @include description-regular($neutral4);
  }
}
