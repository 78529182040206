@import "src/app/styles/variables";

.edit-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  min-height: 50px;
  border: 1px solid $neutral5;
  border-radius: 8px;
  justify-content: space-between;

  &__inner {
    display: flex;
    flex: 1;
  }

  &__actions {
    background-color: $white;
    min-width: 48px;
    display: flex;
    column-gap: 8px;
  }
}
