@import "src/app/styles/variables";

.paragraph-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 175px;
  width: 100%;
  height: 98px;
  border: 1px solid $neutral2;
  border-radius: 4px;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
     height: 100%;
    }
  }

  &-controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #84848499;
  }
}
