@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.ant-dropdown.export-menu {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 8px 12px;
      @include description-regular($neutral5);
    }
    .ant-dropdown-menu-item-disabled {
      color: $neutral2;
    }
    .ant-dropdown-menu-item-group {
      .ant-dropdown-menu-item-group-title {
        @include overline-bold($neutral5);
      }
      .ant-dropdown-menu-item-group-list {
        margin: 0;
        .ant-dropdown-menu-item {
          padding: 8px 12px 8px 24px;
        }
      }
    }
  }
  .ant-dropdown-menu-item-icon {
    width: 14px;
    height: 14px;
  }

}
