@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.quiz-card-item {
  display: flex;
  align-items: center;
  column-gap: 24px;

  &__num {
    @include footnote($neutral3);
    text-transform: uppercase;
  }

  &__controls {
    @include description-regular($neutral4);
    padding: 0 4px;
    background-color: transparent;

    &:disabled {
      color: $neutral2 !important;
    }

    &:hover {
      background-color: $neutral1;
    }
  }

  &__checkbox {
    width: 16px;
    height: 16px;
  }

}
