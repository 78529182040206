@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.user-billing {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;

  &__title {
    @include h5-regular();
    margin-bottom: 8px;
  }

  &__row {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &-block {
    display: flex;
    flex-direction: column;

    &__label {
      @include overline-bold($neutral2);
      margin-bottom: 4px;
    }

    &__text {
      @include body-regular();
      text-transform: capitalize;
    }
  }
}
