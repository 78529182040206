
.course-content-page {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 0 64px 32px 64px;
  column-gap: 24px;

  .ant-tabs .ant-tabs-content {
    position: inherit;
  }
}
