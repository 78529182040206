@import "src/app/styles/mixins";

.studio-player {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  min-height: 300px;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    row-gap: 16px;
  }

  &__settings {
    display: flex;
    flex-direction: column;
  }
}
