@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.header-small {
  display: flex;
  padding: 14px 64px;

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 16px;
  }

  &__title {
    @include h5-regular($neutral5);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    flex-wrap: wrap;
  }
}
