@import "src/app/styles/mixins";

.assign-video-content {

  &__wrap {
    max-width: 908px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-bottom: 12px;
    height: 100%;
    padding-inline-end: 8px;
  }
}

.assign-reading-content__wrap, .assign-quiz-content__wrap {
  max-width: 908px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.assign-video-settings {
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;

    &--or {
      @include h4-regular();
    }
  }
}

.assign-video-studio {
  display: grid;
  grid-template-areas: 'header player'
                        'content player';
  grid-template-columns: minmax(300px, 910px) minmax(500px, 1fr);
  grid-template-rows: auto auto;
  gap: 16px;

  &--header {
    grid-area: header;
  }

  &--content {
    grid-area: content;
  }

  &--player {
    grid-area: player;
    height: fit-content;
    max-height: 100%;

    &.assignment-select__block {
      position: sticky;
      top: 0;
    }
  }

  &__wrap {
    max-width: 100%;

    & .assignment-select__block {
      max-width: 908px;
    }
  }
}
