@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cb-form-item {
  .ant-form-item-label {
    padding-bottom: 8px;
    label {
      @include description-regular($neutral3);
    }
  }
}
