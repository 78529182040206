@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.error-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  &__icon {
    margin-bottom: 30px;
  }

  &__title {
    @include h4-regular($neutral5);
    margin-bottom: 12px;
  }

  &__text {
    @include description-regular($neutral3);
    margin-bottom: 12px;
  }
}
