@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.translation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    @include h4-regular($neutral5);
    margin-bottom: 8px;
  }

  &__text {
    @include description-regular($neutral3);
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-top: 24px;

    .cb-button {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
