.about-form {
  &__field-wrap {
    display: flex;
    position: relative;
    width: 100%;

    .cb-form-item {
      width: 100%;
    }

    .button-sparking {
      position: absolute;
      right: -56px;
      top: 28px;

      .about-form--rtl & {
        right: auto;
        left: -56px;
      }
    }
  }
}
