@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.course-structure-add-outcomes {
  position: relative;
}

.course-structure-add-outcomes-menu {
  position: absolute;
  z-index: 3333;
  top: 24px;
  right: 0;
  width: 400px;
  height: 240px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;

  [dir='rtl'] & {
    right: auto;
    left: 0;
  }

  &__list {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    height: 240px;
    overflow: auto;
    @include scrollSmall()
  }

  &__search {
    display: flex;
    padding: 8px 12px;

    .ant-input-affix-wrapper.cb-input {
      height: 32px;
      padding-left: 8px;
      background-color: $neutral1;

      .ant-input {
        background-color: $neutral1;
      }
    }

    .ant-input-affix-wrapper-focused.cb-input {
      height: 32px;
      background-color: $white;
      box-shadow: none;

      .ant-input {
        background-color: $white;
      }
    }
  }
}

.course-structure-add-outcomes-menu-item {
  display: flex;
  align-items: center;
  padding: 7px 12px;
  column-gap: 8px;

  &--selected {
    background-color: $neutral0;
  }

  &:hover {
    cursor: pointer;
    background-color: $neutral0;
  }


  &__text {
    @include footnote($neutral5);
  }

  &__selected {
    min-width: 14px;
    min-height: 14px;
    margin-left: auto;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
