@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.ant-btn.cb-button {
  font-size: 13px !important;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  box-shadow: none !important;
  border-radius: 40px;

  &:disabled {
    border-color: $neutral1 !important;
    background-color: $neutral1 !important;
    color: $neutral2 !important;
  }

  &:not(.ant-btn-loading) {
    .anticon {
      transform: scale(1) !important;
    }
  }

  &.ant-btn-default {
    background-color: transparent;

    &:hover {
      background-color: $white;
      border-color: $neutral3;
      color: $neutral3;
    }
  }

  &.ant-btn-circle.ant-btn-icon-only {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &.ant-btn-default {
      background-color: initial;

      &:hover {
        background-color: $white;
        border-color: $neutral3;
        color: $neutral3;
      }
    }

    &.ant-btn-text {
      background-color: initial;

      &:hover {
        background-color: $white;
        color: $neutral3;
      }
    }
  }

  &.ant-btn-primary:disabled {
    border-color: $neutral1;
  }
}

// Custom size XL
.ant-btn.ant-btn-lg.cb-button--xl {
  padding: 14px 24px;
  border-radius: 40px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-lg.ant-btn-icon-only.cb-button--xl {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 48px;
  height: 48px;
}

// Custom size XXL
.ant-btn.ant-btn-lg.cb-button--xxl {
  padding: 18px 24px;
  border-radius: 40px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
