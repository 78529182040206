@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.header-nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;

  &__list {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }

  &__item {
    position: relative;
  }

  &__link {
    display: inline-block;
    text-decoration: none;
    @include overline-regular($neutral3);

    &.active {
      font-weight: 700;
      color: $neutral5;

      &:before {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: -19px;
        background-color: $neutral5;
      }
    }

    &--disabled {
      color: $neutral2;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
