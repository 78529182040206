
.assignments-setting-quiz {
  display: flex;
  width: 100%;
  column-gap: 48px;

  .assignment-settings-quiz-questions {
    width: 300px;
  }

  .assignment-settings-quiz-questions-type {
    width: 200px;
  }
}
