@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.gen-pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cb-spinner {
    margin-top: 16px;
  }

  &__title {
    @include body-regular($neutral5);
  }

  &__text {
    @include description-regular($neutral3);
  }
}
