@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.ant-message-notice {
  .anticon {
    display: none;
  }

  .ant-message-notice-content {
    padding: 0 !important;
    font-size: 14px;
    line-height: 20px;
    color: $neutral5;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }
  .ant-message-custom-content {
    padding: 10px 16px;
  }

  .ant-message-success {
    background-color: $green1;
  }

  .ant-message-error {
    background-color: $red1;
  }

  .ant-message-warning {
    background-color: $orange1;
  }

  .ant-message-loading {
    .anticon {
      display: inline-block;
      color: $neutral5;
    }
  }
}

