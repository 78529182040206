@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.avatar-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid $neutral2;

  &__title {
    @include body-regular($neutral5);
  }

  &__controls {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}
