.lo-group {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border: 1px solid;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
