@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.header-user {
  display: flex;
  align-items: center;

  .ant-avatar {
    background-color: $neutral3;
  }

  &__user-name {
    margin:0 8px;
    @include body-regular($white);
    text-decoration: none;
  }

  .logout-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 0;
  }
}
