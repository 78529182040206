@import "src/app/styles/variables";
@import "src/app/styles/mixins";
@import "src/app/styles/variables";

.assignment-select {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;

  overflow: hidden;
  height: calc(100vh - 120px - 32px - 36px );

  .cb-icon-magic {
    margin-left: 8px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: 8px;
    row-gap: 24px;
    overflow: auto;

    .ant-tabs-content-holder {
      overflow: auto;
      @include scrollSmall();
    }

    .ant-tabs-nav {
      position: sticky;
      top: 0;
      z-index: 1000;
      background-color: $neutral0;
    }

    .ant-tabs {
      height: 100%;
      position: relative;

      .ant-tabs-content {
        height: 100%;

        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }

    [dir='rtl'] & {
      padding-right: 0;
      padding-left: 8px;
    }
  }

  &-empty {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;

    &__text {
      margin-top: 12px;
      @include body-regular($neutral4);
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    background-color: $white;
    border-radius: 12px;
    padding: 12px;

    &--full {
      height: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__regen-video {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-text {
      @include body-regular();
      margin-bottom: 8px;
    }
  }

  &-video-points-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    background-color: $white;
    border-radius: 12px;
    padding: 12px;
  }

  &__results {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

  }
}
