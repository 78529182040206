@import "src/app/styles/variables";
@import "src/app/styles/mixins";


.cb-modal-finally-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 284px;

  &__title {
    @include h4-regular($neutral5);
    margin-bottom: 8px;
  }

  &__text {
    @include description-regular($neutral3);
    margin-bottom: 24px;
  }

  &__close {
    width: 115px;
  }
}
