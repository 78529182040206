@import "src/app/styles/variables";


.profile-layout {
  &__back-btn {
    border-color: $neutral0;

    &:hover {
      border-color: $neutral3;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 64px;
    border-bottom: 1px solid rgba(27, 25, 23, 0.1);
  }
}
