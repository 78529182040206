
.affiliate-page {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &-alert {
    max-width: 960px;
  }
}
