@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.add-element-drop {
  .ant-dropdown-menu {
    padding: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .ant-dropdown-menu-item {
    padding: 8px 12px !important;
    &:hover {
      background-color: $neutral1 !important;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &-text {
      @include description-regular($neutral5);
      text-transform: capitalize;
    }

    .ccm-svg-inline {
      width: 12px;
      height: 12px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
