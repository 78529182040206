@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.settings-form {
  display: flex;
  flex-direction: column;

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    &-course-type {
      display: flex;
      column-gap: 32px;
    }
  }

  .settings-form__lang {
    width: 200px;
  }

  &__course-types {
    min-width: 300px;
    width: fit-content;
  }

  &__slider-webinar {
    min-width: 350px;
    width: fit-content;
  }

  &__row-sliders {
     .settings-form__slider {
      width: 180px;
       margin: 0;

       .ant-form-item-row {
         height: 100%;
         justify-content: space-between;

         .ant-form-item-control {
           flex-grow: 0;
         }
       }

      &:nth-child(2) {
        width: 225px;
      }

       .ant-form-item-label {
         label {
           @include description-regular($neutral5);
           text-transform: none;
           letter-spacing: normal;
         }
       }

       .ant-slider-mark-text {
          text-transform: capitalize;
       }
    }
  }

  &__label {
    @include overline-regular($neutral5);
    margin-bottom: 21px;
  }
}

.settings-form-popover {
  .ant-popover-inner {
    max-width: 300px;
  }
}

.ant-select-dropdown {
  .rc-virtual-list-holder {
    overflow-y: auto !important;
    @include scrollSmall();
  }
}

