@import "src/app/styles/variables";
@import "src/app/styles/mixins";

$name: 'generation-status-view';

.#{$name} {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 8px 16px;
  background-color: $white;
  border-radius: 40px;

  &__status {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    background-color: $green3;

    .#{$name}--busy & {
      background-color: $blue3;
    }
  }

  &__label {
    @include description-regular();
    font-weight: 500;
    margin-inline-end: 4px;
  }

  &__text {
    @include description-regular();
    white-space: pre;
  }

}
