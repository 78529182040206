@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cb-input {
  border-color: $neutral1;
  background-color: $neutral1;

  &:focus {
    box-shadow: none;
  }
}

.cb-input--xl {
  padding: 11px 12px 11px 16px;
  @include body-regular($neutral5);
}

.cb-input-password {
  border-color: $neutral1;
  background-color: $neutral1 !important;
  box-shadow: none;

  input {
    background-color: $neutral1 !important;
  }
}
