@import "src/app/styles/mixins";
@import "src/app/styles/variables";

.assign-video-ex-url {
  &__wrap {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  &__container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__error {
    &-text {
      @include overline-bold($red3);
    }
  }
}
