@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cs-collapse-head {
  display: flex;
  padding: 8px 4px 8px 24px;

  .cs-collapse--lvl-2 & {
    padding: 12px 4px 12px 24px;
  }

  .cs-collapse--lvl-2.cs-collapse--print & {
    border-bottom: 1px solid $neutral1;
  }

  &__info {
    display: flex;
    align-items: center;
    width: 96px;
    min-width: 96px;
    height: 24px;
    padding-left: 28px;
  }

  &__content {
    display: flex;
    width: 100%;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    width: 108px;
    min-width: 108px;
    margin-left: 8px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 8px;
    }


    &--hidden {
      width: 24px;
      min-width: 24px;
    }
  }

  &__count {
    display: inline-flex;
    min-width: 48px;
    width: 48px;
    @include footnote($neutral3);

    &-wrap {
      display: flex;
      align-items: center;
      width: 56px;
      height: 32px;
      margin-right: 8px;
    }
  }

  &__title {
    min-height: 30px;
    background-color: transparent;
    @include h5-regular($neutral5);
    padding: 0 4px;
    white-space: pre-wrap;
    word-wrap: break-word;

    .cs-collapse--lvl-2 & {
      @include body-regular($neutral5);
    }

    &:hover {
      background-color: $neutral1;
    }

    &:disabled {
      color: $neutral2 !important;

      .cs-collapse--disabled & {
        background-color: transparent;
        color: $neutral5 !important;

        &:hover {
          cursor: auto;
        }
      }
    }
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: 8px;
    width: 104px;
    min-width: 104px;
    column-gap: 4px;
    row-gap: 4px;
    padding-top: 3px;
  }
}
