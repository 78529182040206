@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.ant-dropdown,
.ant-dropdown-menu-submenu {
  .ant-dropdown-menu {
    padding: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    .ant-dropdown-menu-submenu-title {
      padding: 0;
      &:hover {
        background-color: $neutral1 !important;
      }
    }
    .ant-dropdown-menu-item {
     padding: 0;
      &:hover {
        background-color: $neutral1 !important;
      }
    }
  }
}
