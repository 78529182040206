@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.create-course-error {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background-color: $red1;
  border-radius: 8px;

  &__text {
    @include body-regular($neutral5);
  }
}
