@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.create-course-upload {
  display: flex;
  flex-direction: column;

  .block-title {
    margin-bottom: 8px;
  }

  &__text {
    @include description-regular($neutral5);
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    align-items: flex-start;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  .ant-upload-drag {
    border: 1px solid $neutral1;
    border-radius: 8px;
    background-color: $neutral0;

    .ant-upload-btn {
      padding: 0;
    }
  }

  &__progress {
    width: 88px;
    height: 22px;

    .ant-progress-bg {
      height: 2px !important;
    }
  }

  &__drag-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 640px;
    height: 164px;

    &-label {
      @include description-regular($neutral5);
      margin-top: 16px;
    }

    &--error {

    }
  }

  .cb-drag--error {
    .ant-upload-drag {
      border-color: $red2 !important;
      background-color: $red1 !important;
    }
    .create-course-upload__drag-content-label {
      color: $red3;
    }

    .anticon-plus {
      color: $red3;
    }
  }
}
