.form-footer {
  display: flex;
  align-items: center;

  &__submit {
    .cb-icon-magic {
      margin-left: 8px;

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
}
