@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.course-card-action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 32px) / 3);
  height: 320px;
  padding: 24px 32px;
  border: 1px solid $neutral3;
  border-radius: 12px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &--no {
    &:hover {
      cursor: auto;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text {
    @include overline-bold($neutral5);
    margin-top: 24px;
    text-align: center;
  }
}
