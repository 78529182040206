@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.status-label {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 8px;
  @include overline-bold($white);

  &--new,
  &--in_progress {
    background-color: $blue3;
  }

  &--done {
    background-color: $green3;
  }

  &--cancelled,
  &--failed {
    background-color: $red3;
  }
}
