@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.regen-popup-content {
  display: flex;
  flex-direction: column;
  width: 400px;

  &__title {
    @include overline-bold($neutral5);
    margin-bottom: 12px;
  }

  &__extra {
    display: inline-flex;
    flex-direction: column;

    &-title {
      display: inline-flex;
      @include body-regular($red3);
      font-weight: 500;
      column-gap: 8px;
      margin-bottom: 8px;
    }

    &-text {
      @include description-regular($neutral5);
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 12px;
    gap: 12px;
  }
}
