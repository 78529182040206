@import "src/app/styles/variables";

$name: 'paragraph';

.#{$name} {
  display: flex;
  width: 100%;
  position: relative;
  padding-inline-start: 30px;

  .cb-text-area {
    background-color: transparent;
    border-color: transparent;

    &:focus {
      border-color: $neutral5;
    }
  }

  &--hovered {
    .cb-text-area {
      border-color: $neutral5;
    }

  }

  &__actions {
    position: absolute;
    z-index: 100;
    left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .ant-btn.cb-button.ant-btn-circle.ant-btn-icon-only.ant-btn-text {
      background-color: $neutral2;
    }
  }
}
