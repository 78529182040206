@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cs-collapse {
  display: flex;
  flex-direction: column;

  .ant-input-borderless[disabled] {
    background-color: $neutral0;
  }

  &--lvl-2 {
    background-color: $white;
    border-radius: 20px;

    .ant-input-borderless[disabled] {
      background-color: $white;
    }
  }

  &--disabled {
    background-color: transparent;

    .ant-input-borderless[disabled] {
      background-color: $neutral0;
    }
  }

  &-content {
    opacity: 0;
    transition: 0.4s max-height;
    height: 0;

    &--open {
      height: auto;
      opacity: 1;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      overflow: initial;
      row-gap: 12px;

      &-add {
        display: flex;
        align-items: center;
        height: 68px;
        padding: 16px 24px 16px 40px;
        margin-top: 12px;
        background-color: $white;
        border-radius: 20px;

        .cb-input {
          padding: 0 11px 0 0;
          margin-right: 24px;
          @include h5-regular($neutral5);
        }
      }
    }
  }
}

