.course-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 1000px;

  &-public {
    .lo-structure-view {
      height: calc(100vh - 120px);
    }
    .course-structure {
      height: calc(100vh - 120px);
    }
  }
}
