@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.module-lo {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1104px;
  padding-bottom: 45px;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 484px;
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 20px;

    .cb-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__add {
    width: fit-content;
  }

  &__submit {
    margin: 32px 0 0 auto;
    width: fit-content;

    svg {
      margin-left: 8px;

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
}
