
$neutral0: #F8F8F8;
$neutral1: #E9E9E9;
$neutral2: #C8C6C5;
$neutral3: #848484;
$neutral4: #605F5E;
$neutral5: #1E1E1E;
$neutral7: #898990;

$red1: #F8EAE7;
$red2: #E89C8B;
$red3: #D73C1D;
$red4: #8D2008;
$red5: #511102;

$orange1: #F3DFC2;
$orange2: #EDC687;
$orange3: #E29416;
$orange4: #CC6E00;
$orange5: #7E3D01;

$green1: #D5E4CA;
$green2: #ADCC94;
$green3: #65B129;
$green4: #2E6006;
$green5: #2D3C22;

$blue1: #DAD9EB;
$blue2: #A7A6D5;
$blue3: #5149D3;
$blue4: #191078;
$blue5: #0C0035;

$main: #ECE9E5;
$white: #FFFFFF;
