
.billing-page {
  display: flex;
  flex-direction: column;
  padding: 32px 64px;
  width: fit-content;
  margin: 0 auto;

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
