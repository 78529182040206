@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.course-card {
  display: flex;
  flex-direction: column;
  width: calc((100% - 32px) / 3);
  height: 320px;
  padding: 24px 32px;
  background-color: $main;
  border-radius: 12px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &__lang-list {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  &__title {
    @include h4-regular();
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &-step {
    display: inline-flex;
    align-items: center;

    &__label {
      @include body-regular();
    }

    &__text {
      @include overline-bold();
      margin-left: 4px;
    }
    &:hover {
      cursor: auto;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    &__wrap {
      display: flex;
      flex-direction: column;

      &-r {
        justify-content: flex-end;
      }
    }

    &__text {
      @include description-regular($neutral4);
    }
  }

  &-recognition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__text {
      margin-top: 12px;
      @include description-regular($neutral5);
    }
  }

  &-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &--translate {
      justify-content: flex-start;
    }

    &__text {
      margin-top: 12px;
      @include description-regular($neutral3);
    }

    &__loading {
      display: inline-flex;
      width: 52px;
      height: 52px;
      position: relative;
    }

    &__current {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &-text {
        @include footnote($neutral5);
      }
    }
    &:hover {
      cursor: auto;
    }
  }
}
