@import "src/app/styles/variables";

@mixin h1-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 56px;
  line-height: 64px;
  color: $color;
  margin: 0;
}

@mixin h1-regular($color: $neutral5) {
  font-weight: 300;
  font-size: 56px;
  line-height: 64px;
  color: $color;
  margin: 0;
}

@mixin h2-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  color: $color;
  margin: 0;
}

@mixin h2-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  color: $color;
  margin: 0;
}

@mixin h3-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: $color;
  margin: 0;
}

@mixin h3-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: $color;
  margin: 0;
}

@mixin h4-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: $color;
  margin: 0;
}

@mixin h4-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: $color;
  margin: 0;
}

@mixin h5-medium($color: $neutral5) {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $color;
  margin: 0;
}

@mixin h5-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $color;
  margin: 0;
}

@mixin body-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}

@mixin body-regular-short($color: $neutral5) {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: $color;
}

@mixin footnote($color: $neutral3) {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $color;
}

@mixin description-regular($color: $neutral5) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color;
}

@mixin overline-bold($color: $neutral5) {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: $color;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin overline-regular($color: $neutral3) {
  font-weight: 400;
  font-size: 13px;
  color: $color;
  letter-spacing: 0.1em;
  line-height: 20px;
  text-transform: uppercase;
}

@mixin responsive-block($ratioY: 16, $ratioX: 9, $width: 100) {
  position: relative;
  &::before {
    content: "";
    display: block;
    width: percentage(calc($width / 100));
    padding-top: percentage(calc($ratioX / ($ratioY / ($width / 100))));
  }
  & > *:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  & > img:first-child {
    object-fit: cover;
  }
}

@mixin scrollHidden() {
  &::-webkit-scrollbar {
    width: 0;
  }
}

@mixin scrollSmall() {

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 8px !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: $neutral1;

  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $neutral5;
  }
}
