@import "src/app/styles/mixins";
@import "src/app/styles/variables";

.slide-card {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 275px;
  height: 212px;
  border-radius: 8px;


  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  &-controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #84848499;

    &__wrap {
      position: relative;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
  }
}
