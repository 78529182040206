@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.quiz-card {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 20px;

  &-content {
    opacity: 0;
    transition: 0.4s max-height;
    height: 0;

    &--open {
      height: auto;
      opacity: 1;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      overflow: initial;
      row-gap: 12px;
      padding: 12px 44px 12px 64px;

      [dir='rtl'] & {
        padding: 12px 64px 12px 44px;
      }

      .quiz-card-content--empty & {
        padding: 0;
      }

      &--print {
        .ant-input-disabled {
          color: $neutral4 !important;

          &:hover {
            background-color: transparent;
            cursor: auto;
          }
        }
        .ant-checkbox-wrapper-disabled .ant-checkbox-inner {
          background-color: $white !important;
          border-color: $neutral2 !important;

          &:after {
            border-color: $white !important;
          }
        }
        .ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
          background-color: $neutral5 !important;
        }
      }
    }


  }
}
