@import "src/app/styles/mixins";
@import "src/app/styles/variables";

.user-invite {
  display: flex;
  flex-direction: column;

  &__title {
    @include description-regular($neutral3);
    margin-bottom: 8px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 16px;
  }

  &__str {
    @include body-regular();
    display: inline-block;

  }

  .ant-input {
    width: 200px;
  }
}
