
.files-upload-list {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.files-upload-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button[disabled] {
    .anticon {
      cursor: not-allowed;
    }
  }
}
