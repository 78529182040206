@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.course-create-example {
  display: flex;
  flex-direction: column;
  height: auto;

  &__label {
    @include description-regular($neutral5);
    margin-bottom: 12px;
  }

  &__wrap {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 22px 24px 0 27px;
    border: 1px solid $neutral1;
    border-radius: 8px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__link {
    position: absolute;
    top: 11px;
    right: 15px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $neutral5;
    border-radius: 50%;
    color: $neutral0;

    &:hover {
      color: $neutral0;
      background-color: $neutral3;
    }
  }
}
