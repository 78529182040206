@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.lo-item {
  display: flex;

  .lo-item__actions {
    display: flex;
    justify-content: flex-end;
    min-width: 52px;
    column-gap: 4px;

    &-edited {
      margin-top: 8px;
      margin-right: 4px;
      height: fit-content;
    }
  }
}
