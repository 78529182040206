@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  .cb-form-item {
    width: 100%;
  }

  &-apikey {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 8px;

    &__title {
      @include body-regular($neutral5);
      font-weight: 500;
    }

    &__text {
      @include body-regular($neutral3);
      font-weight: 500;
    }
  }

  &__emeil-text {
    @include body-regular($neutral5);
    margin-right: 4px;
  }
}
