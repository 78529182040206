@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.link-button-content {
  display: flex;
  flex-direction: column;
  max-width: 480px;

  &__top {
    display: inline-flex;
    align-items: center;
  }

  &__bottom {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-top: 24px;
  }

  .cb-input {
    width: 294px;
  }

  &__title {
    margin-left: 10px;
    @include body-regular($neutral5);
  }
}
