@import "src/app/styles/variables";

.no-auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &__actions {
    position: absolute;
    top: 20px;
    right: 50px;
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .switch-lang {
    .ant-select-selector {
      background-color: $neutral0;
    }
  }
}
