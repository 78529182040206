@import "src/app/styles/variables";

.create-course-file-view {
  display: flex;
  width: 640px;
  padding: 12px 16px;
  background-color: $neutral0;
  border: 1px solid $neutral1;
  border-radius: 4px;
}
