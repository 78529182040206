@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.lo-item {
  column-gap: 12px;

  &__inner {
    display: flex;
    width: 100%;
    min-height: 24px;
    column-gap: 17px;
  }

  &__name {
    padding: 0;
    width: 100%;
    @include description-regular($neutral5);
    background-color: transparent;

    &:disabled {
      background-color: $white;
    }
  }



  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.lo-item-parent {
  .lo-item__tag {
    display: inline-block;
    min-width: 31px;
    @include h5-regular();
  }

  .lo-item__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  &:not(:last-child) {
    margin-bottom: 19px;
  }
}
