@import "src/app/styles/mixins";

.video-player {

  & > div {
    width: auto !important; // Reset width
    height: auto !important; // Reset height
  }

  .react-player {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }
}
