@import "src/app/styles/mixins";

.course-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  row-gap: 12px;

  .cb-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
