@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.lo-structure-view-item {
  display: flex;
  column-gap: 12px;

  &__tag {
    margin-right: 8px;
    @include description-regular($neutral4);

    .lo-structure-view-item--parent & {
      min-width: 32px;
      @include h5-regular();
    }
  }

  &__text {
    @include footnote($neutral4);

    .lo-structure-view-item--parent & {
      @include description-regular($neutral5);
    }
  }
}
