@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.quiz-card-head {
  display: flex;
  width: 100%;
  padding: 12px;
  column-gap: 12px;

  &__count {
    width: 36px;
    min-width: 36px;

    span {
      @include footnote($neutral3);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 8px;
  }


  &__controls {
    padding: 0 4px;
    background-color: transparent;

    &:disabled {
      color: $neutral2 !important;
    }

    &:hover {
      background-color: $neutral1;
    }
  }

  &__title {
    @include body-regular($neutral5);
  }

  &__description {
    @include description-regular($neutral4);
  }

  &__actions {
    display: flex;
    column-gap: 4px;
    margin-left: auto;
  }
}
