@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cb-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-width: 31px;
  height: 18px;

  &__text {
    @include footnote($neutral0);
  }

  &--rectangle {
    border-radius: 2px;
    min-width: 23px;
    padding: 0 4px;
  }
}
