@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.create-course-settings {
  display: flex;
  flex-direction: column;

  &__wrap {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }

  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 11px 24px;
    background-color: $neutral0;
    border: 1px solid $neutral1;
    border-radius: 8px;

    &-mmoc {
      min-width: 251px;
    }

    &-duration {
      width: 100%;

      .cb-slider {
        margin-top: 0;
        margin-bottom: 28px;
      }

      .ant-slider-rail {
        background-color: $neutral0;
      }
      .ant-slider-mark-text {
        @include description-regular($neutral5);
      }
    }
  }

  &__row {
    display: inline-flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__MMOC {
    @include body-regular($neutral5);
  }

  &__text {
    @include description-regular($neutral4);
  }

  &__label {
    @include body-regular($neutral5);
  }
}
