
.google-auth {
  width: 100%;
  position: relative;

  .cb-icon {
    position: absolute;
    left: 20px;
  }
}
