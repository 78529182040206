@import "src/app/styles/mixins";

.lo-structure-view {
  display: flex;
  flex-direction: column;
  min-width: 374px;
  overflow: hidden;
  height: calc(100vh - 120px - 32px - 36px );
  padding: 18px 0;

  &__title {
    line-height: 56px;
    margin-bottom: 0;
  }


  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-right: 6px;
    @include scrollSmall();

    [dir='rtl'] & {
      padding-right: 0;
      padding-left: 6px;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    min-width: 360px;
    padding: 12px 16px;
    border: 1px solid;
    border-radius: 12px;
    row-gap: 12px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
