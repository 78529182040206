@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.feedback {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translatex(-50%);
  background-color: $white;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  &__inner {
    display: flex;
    align-items: center;
    padding: 8px 24px;
  }

  &__text {
    margin-right: 12px;
    @include description-regular($neutral5);

    [dir='rtl'] & {
      margin-left: 12px;
      margin-right: 0;
    }
  }

  &__like {
    margin-right: 4px;
    background-color: $green3;

    [dir='rtl'] & {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  &__dislike {
    background-color: $red3;
  }
}
