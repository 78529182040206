@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cb-text-area {
  border-color: $neutral1;
  background-color: $neutral1;

  @include scrollSmall();

  &:focus {
    box-shadow: none;
  }

  &.ant-input-lg {
    padding: 8px 16px;
    @include body-regular();
  }

  &.ant-input[disabled] {
    color: $neutral2;
  }

  .ant-input-borderless[disabled] {
    background-color: inherit;
  }
}
