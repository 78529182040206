.cb-spinner {
  display: inline-flex;
  width: fit-content;

  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    pointer-events: none;
    border-radius: 12px;
  }
}
