.assignment-video--extra {
  display: flex;
  align-items: center;
  column-gap: 8px;

  .ant-btn {
    display: flex;
    align-items: center;
  }
}
