@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cb-modal-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 64px;

  &__title {
    @include h3-regular();
    margin: 0;

    &--h4 {
      @include h4-regular();
    }
  }

  &__close {
    position: absolute;
    z-index: 9999;
    top: 12px;
    right: -60px;


    &:hover {
      cursor: pointer;
    }
  }
}
