@import "src/app/styles/mixins";

.assignment-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - 120px - 32px - 36px - 120px);

  &__toggle {
    width: fit-content;
  }

  &__top {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    padding: 8px;
    row-gap: 12px;
    margin-bottom: 12px;

    &-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .block-title {
        line-height: 32px;
        margin-bottom: 0;
      }
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;

    .cb-icon-magic {
      margin-left: 8px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-inline-end: 8px;
    overflow: auto;
    @include scrollSmall();
  }
}
