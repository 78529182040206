@import "src/app/styles/variables";
@import "src/app/styles/mixins";


.price-card {
  display: flex;
  flex-direction: column;
  padding: 55px 15px 25px;
  width: 260px;
  background: $white;
  box-shadow: 0 45px 35px rgba(16,30,54,0.06);
  border-radius: 12px;

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include body-regular();
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    background-color: $neutral5;

    &-w {
      background-color: $white;
    }

    svg,
    img {
      width: 48px;
      height: 48px;
    }
  }

  &__hint {
    @include footnote();
    text-align: center;
    margin: 4px;

    &--empty {
      min-height: 18px;
    }
  }

  &__value {
    font-size: 60px;
    line-height: 1;
    font-weight: 700;

    sup {
      font-size: 20px;
      margin-inline-end: 3px;
      top: -1.5em;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 4px;
    margin-top: 8px;
    p {
      @include description-regular();
      text-align: center;
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    flex: 0;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
  }
}
