@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cs-collapse-add-lesson {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 12px 8px 12px 24px;
  margin-top: 12px;
  background-color: $white;
  border-radius: 20px;

  .cb-input {
    padding: 0 11px 0 8px;
    @include body-regular($neutral5);
    background-color: $neutral1 !important;
    max-width: 600px;
    margin: 0;
  }
}
