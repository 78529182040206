.learning-outcomes-page {
  display: grid;
  grid-template-columns: 384px auto;
  padding: 0 64px 32px 64px;
  column-gap: 24px;

  .block-title {
    line-height: 56px;
    margin-bottom: 0;
  }
}
