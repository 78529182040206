@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.signup {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }

  &__title {
    @include h2-regular();
    margin-bottom: 16px;
  }
}
