@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.word-counter-tag {
  position: absolute;
  z-index: 3;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: max-content;
  padding: 8px 16px;
  background-color: $neutral4;
  border-radius: 8px;

  &__text {
    @include description-regular($neutral0);
  }
}
