@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.subscription-active {
  display: flex;
  height: 48px;
  @include overline-bold($green3);
  align-items: center;
}

.ant-popconfirm-buttons {
  .ant-btn-primary {
    box-shadow: none;
  }
}

.ant-popconfirm {
  .ant-popconfirm-description {
    margin-inline-start: 8px;
  }
}
