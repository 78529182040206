@import 'normalize';
@import 'variables';
@import "short";
@import "fonts";
@import "shared/ui/message/styles";
@import "shared/ui/dropdown/styles";
@import "shared/ui/popover/styles";

* {
  box-sizing: border-box;
  &:hover,
  &:focus {
    outline: none;
  }
  outline: none;
}

html {
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'PT Root UI', sans-serif;
  font-size: 14px;
  line-height: 1;
  letter-spacing: normal;
  font-style: normal;
  background-color: $neutral0;
  @include scrollSmall();
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px $neutral1 inset !important;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#root {
  min-width: 1024px;
}

.container {
  max-width: 1440px;
  padding: 32px 64px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.container-small {
  max-width: 900px;
  padding: 48px 0;
  width: 100%;
  height: 100%;
}

.container-center {
  margin: 0 auto;

  .cb-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ant-modal-root {
  .ant-btn-primary {
    box-shadow: none;
  }
}
