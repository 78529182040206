@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.promo-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc((100% - 32px) / 3);
  height: 320px;
  padding: 24px 32px;
  border: 1px solid $neutral3;
  border-radius: 12px;
  text-decoration: none;

  &__close {
    position: absolute;
    z-index: 2;
    top: 4px;
    right: 4px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__title {
    @include h4-regular();
  }

  &__description {
    @include description-regular();
    white-space: pre-line;
  }
}
