@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.assignment-select-head {
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 16px;
  }

  &__index {
    @include footnote($neutral3);
  }

  &__icon {
    width: 16px;
    height: 16px;

    .ccm-svg-inline {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__controls {
    padding: 0 4px;
    background-color: transparent;

    &:disabled {
      color: $neutral2 !important;
    }

    &:hover {
      background-color: $neutral1;
    }
  }

  &__title {
    @include body-regular($neutral5);
    min-height: 24px !important;
    height: 24px !important;
  }

  &__description {
    @include description-regular($neutral4);
    margin-bottom: 24px;
  }

  &__lo {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
