@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.lo-description-view {
  display: flex;
  flex-direction: column;
  width: 320px;
  padding-top: 18px;

  &__label {
    margin-bottom: 22px ;
    @include overline-regular($neutral5);
  }

  &__text {
    @include description-regular($neutral5);
    margin-bottom: 32px;
  }
}
