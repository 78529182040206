@import "src/app/styles/mixins";

.course-print {
  width: 210mm;
  min-height: 297mm;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 48px 24px;

  &-quizzes-block {
    margin-bottom: 24px;

    .assignment-select-head__controls {
      &:hover {
        background-color: transparent;
        cursor: auto;
      }
    }
  }

  &-quizzes-content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 12px;

    .quiz-card-head__controls {
      &:hover {
        background-color: transparent !important;
      }
    }
  }


}
