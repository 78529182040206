@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.login-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  margin: 0 auto;
}

.auth-page {
  display: flex;
  justify-content: center;
}

.signup-to-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $neutral0;

  &__title {
    @include h4-regular();
    margin-bottom: 24px;
  }

  .cb-button {
    min-width: 160px;
  }
}
