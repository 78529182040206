@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.add-structure-module {
  margin-top: 12px;
  padding-left: 24px;

  .ant-btn.cb-button:disabled {
    border: none;
    background-color: transparent !important;
  }

  &__info {
    display: flex;
    align-items: center;
    width: 96px;
    padding-left: 38px;
    margin-right: 4px ;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 56px;
    margin-left: 4px;
  }

  &__inner {
    display: flex;
    align-items: center;
    height: 54px;
    padding: 12px 4px 12px 0;

    .cb-input {
      padding-left: 4px;
      height: 30px;
      background-color: $neutral1;
      @include body-regular($neutral5);
      line-height: 24px;
    }
  }
}
