@import "src/app/styles/variables";

.course-header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: $neutral0;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 64px;
    border-bottom: 1px solid rgba(27, 25, 23, 0.1);
  }
}
