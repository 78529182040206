@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.item-type-select {
  position: relative;
  display: flex;

  .cb-button.item-type-select__button {
    width: 16px;
    height: 16px;
    border: none;
    background: none;
    padding: 0;

    &--disabled {
      background-color: transparent !important;
      svg {
        path {
          fill: $neutral2;
        }
      }
    }
  }

  &__menu {
    position: absolute;
    z-index: 3333;
    top: 23px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-height: 152px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: $white;
  }
}

.item-type-select-item {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  padding: 6px 12px;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__text {
    margin-right: 8px;
    white-space: nowrap;
    @include description-regular($neutral5);
  }

  &__selected {
    width: 14px;
    height: 14px;
    margin-left: auto;
  }

  &:hover {
    cursor: pointer;
    background-color: $neutral0;
  }
}

