@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.upload-avatar {
  width: 150px;
  height: 150px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: $neutral1;
  }

  & img {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
  }

  .anticon-plus {
    font-size: 16px;
  }

  &:hover {
    cursor: pointer;
  }
}
