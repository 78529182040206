@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cs-element-view {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px 36px 8px 24px;

  .cb-input {
    padding: 0 11px 0 0;
    background-color: transparent;
  }

  &__inner {
    display: flex;
  }

  &__info {
    display: flex;
    align-items: center;
    width: 96px;
    min-width: 96px;
    height: 24px;
    padding-left: 28px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: 8px;
    width: 104px;
    min-width: 104px;
    column-gap: 4px;
    row-gap: 4px;
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 16px;
    height: 24px;
    margin-right: 8px;

    .ccm-svg-inline {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }



  &__count {
    @include footnote($neutral3);

    &-wrap {
      display: flex;
      width: 56px;
      min-width: 56px;
      margin-right: 8px;
    }
  }

  &__title {
    width: 100%;
    @include body-regular($neutral5);
    padding: 0 4px;
    background-color: transparent;
    white-space: pre-wrap;
    word-wrap: break-word;

    &:disabled {
      color: $neutral2 !important;

      .cs-collapse--disabled & {
        background-color: transparent;
        color: $neutral5 !important;

        &:hover {
          cursor: auto;
        }
      }
    }
  }

  &__description {
    width: 100%;
    @include description-regular($neutral4);
    padding: 0 4px;
    background-color: transparent;
    white-space: pre-wrap;
    word-wrap: break-word;

    &:disabled {
      color: $neutral2 !important;

      .cs-collapse--disabled & {
        background-color: transparent;
        color: $neutral5 !important;

        &:hover {
          cursor: auto;
        }
      }
    }
  }

  &__text {
    @include description-regular($neutral4);
  }

  &__actions {
    display: flex;
    align-items: center;
    height: 24px;
    width: 52px;
    margin-left: 8px;
  }
}
