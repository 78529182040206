@import "src/app/styles/variables";
@import "src/app/styles/mixins";


.avatar-position {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid $neutral2;
  border-bottom: 1px solid $neutral2;

  &__title {
    @include body-regular($neutral5);
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__button {
    width: 60px;
    height: 40px;
    padding: 4px;
    border: 1px solid $neutral1;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      border-color: $neutral3;
      transition: 0.3s;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &--active {
      border-color: $neutral5;
      transition: 0.3s;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 30%;
      border-color: $neutral1;

      &:hover {
        border-color: $neutral1;
      }
    }
  }
}
