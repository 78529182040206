@import "src/app/styles/variables";
@import "src/app/styles/mixins";


.promo-alert-card {
  display: flex;
  padding: 8px 12px;
  border-bottom: 1px solid $neutral3;

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__title {
    @include body-regular();
    font-weight: 500;
  }

  &__description {
    @include description-regular();
    white-space: pre-line;
  }

  .cb-button {
    align-self: center;
  }
}
