@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.curriculum-form-block {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    column-gap: 24px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &-full {
      width: 640px;
    }

    &-short {
      width: 284px;
    }
  }

  &__label {
    @include description-regular($neutral5);
  }

  &__sample {
    @include description-regular($neutral4);
  }
}
