@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cb-select {
  .ant-select-selector {
    box-shadow: none !important;
    background-color: $neutral1 !important;
  }

  .ant-select-selection-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $neutral5 !important;
  }

  &.ant-select-disabled {
    .ant-select-selection-item {
     color: $neutral2 !important;
    }
  }
}

.cb-select--xl {
  .ant-select-selector {
    min-height: 48px !important;
    border-radius: 8px !important;
    padding-left: 16px !important;

    .ant-select-selection-search {
      margin-inline-start: 1px !important;
    }

    .ant-select-selection-placeholder {
      padding-left: 6px !important;
    }

    input {
      height: 46px !important;
    }

    & .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 16px !important;
      line-height: 46px !important;
    }
  }

  &.ant-select-disabled {
    .ant-select-selection-item {
      color: $neutral2 !important;
    }
  }
}
