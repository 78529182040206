@font-face {
  font-family: 'PT Root UI';
  src: local('Root'), url('../../assets/fonts/pt-root-ui_light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: local('Root'), url('../../assets/fonts/pt-root-ui_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: local('Root'), url('../../assets/fonts/pt-root-ui_medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: local('Root'), url('../../assets/fonts/pt-root-ui_bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}


