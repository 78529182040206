@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.modal-export {
  &__hint {
    @include description-regular();
  }
  &__sub-title {
    @include description-regular();
    margin-bottom: 16px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
  }
}
