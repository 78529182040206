@import "src/app/styles/mixins";

.studio-content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background-color: $white;
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  overflow: auto;
  @include scrollSmall();

  &__row {
    display: flex;
    width: 100%;
    column-gap: 24px;
  }


  &__empty-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto auto;
    height: 100%;

    &-text {
      @include h5-medium();
    }
  }
}
