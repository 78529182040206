@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.user-menu {

  &__wrap {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__title {
    @include body-regular($white);
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  &.ant-dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        padding: 8px 12px;

        .cb-icon {
          margin-inline-end: 8px;
        }
      }
    }
  }
}
