.we-contact {
  .form-action {
    display: flex;
    width: 100%;
    padding: 16px 0;

    .cb-button {
      width: 100%;
    }
  }
}
