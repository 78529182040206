@import "src/app/styles/mixins";

.modal-expert {
  &__sub-title {
    @include description-regular();
    margin-bottom: 16px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__result {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;

    .cb-button {
      width: fit-content;
    }

    &-text {
      text-align: center;
      @include h5-medium();
    }
  }
}
