@import "src/app/styles/variables";
@import "src/app/styles/mixins";


.welcome-tutor,
.end-tutor {

  .cb-modal-content {
    .tour__title {
      text-align: center;
    }

    .tour__text {
      text-align: center;
    }
  }

  &__actions {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    column-gap: 32px;
  }
}
