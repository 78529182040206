@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cb-slider {
  .ant-slider-mark {
    top: 20px;
  }
  .ant-slider-mark-text {
    @include footnote($neutral5);
  }
}
