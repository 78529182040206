@import "src/app/styles/variables";

.create-course-divider {
  border-block-start: 2px solid $neutral1;
}

.create-course-action {
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;

  .cb-button {
    width: 100%;
  }
}
