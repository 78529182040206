@import "src/app/styles/variables";


.liner-progress {
  width: 100%;
  height: 2px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, $neutral5, $blue5, $blue3, $blue1, $green3, $green2, $green1);

  &--empty {
    min-height: 2px;
    background-color: transparent;
  }

  &__bar {
    position:absolute;
    width: calc(100% * 2/7);
    height: 100%;
    display: flex;
    animation: move 2s linear infinite;
    background-color: white;
  }
}

@keyframes move
{
  0%   {transform: translate(-100%, 0)}
  100% {transform: translate(calc(7/2*100%), 0)}
}
