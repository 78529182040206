@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.credits-view {
  display: inline-flex;
  align-items: center;
  column-gap: 24px;

  .ant-badge {

    .ant-badge-count {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 25px;
      height: 20px;
      @include description-regular($neutral5);
      border: 1px solid $neutral2;
      border-radius: 10px;
      box-shadow: none;

      .current {
        display: inline-flex;
        height: 20px;
        @include description-regular($neutral5);
      }
    }
  }

  &__text {
    @include body-regular($neutral0);
  }

  &__block {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}
