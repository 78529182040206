@import "src/app/styles/variables";

.cb-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 12px;
}


