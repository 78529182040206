
.price-list {
  display: flex;
  flex-direction: column;

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__inner {
    display: flex;
    column-gap: 16px;
    justify-content: center;
  }
}
