
.user-form-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  padding: 32px;

  .cb-form-item {
    width: 100%;
  }
}
