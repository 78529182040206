@import "src/app/styles/variables";

.ant-dropdown.quiz-more-action-drop {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 8px 12px;
    }
    .ant-dropdown-menu-item-danger {
      color: $red3 !important;
    }
  }

}
