@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.settings-reezonly {
  display: flex;
  flex-direction: column;

  &__title {
    @include h4-medium();
    margin-bottom: 8px;
  }

  &-status {
    display: flex;
    align-items: center;
    min-width: 300px;
    width: fit-content;
    column-gap: 8px;
    padding: 5px 10px;
    margin-bottom: 16px;
    border-radius: 6px;
    background-color: $neutral1;

    &__text {
      @include description-regular();
    }

    & a {
      text-decoration: underline;
    }

    &--new,
    &--in_progress {
      background-color: $blue1;
    }

    &--done {
      background-color: $green2;
    }
    &--rejected {
      background-color: $red2;
    }

  }

  &__text {
    @include description-regular();
    margin-bottom: 16px;
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 32px;

    .cb-form-item {
      width: 100%;
    }
  }

  &__wrap {
    display: flex;
    width: 500px;
  }
}
