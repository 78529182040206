@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.block-title {
  @include overline-regular($neutral5);
  margin-bottom: 16px;

  &--h5 {
    @include h5-regular($neutral5);
    text-transform: capitalize;
    letter-spacing: normal;
    margin-bottom: 16px;
  }
}
