@import "src/app/styles/mixins";

.course-print {
  width: 210mm;
  min-height: 297mm;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 48px 24px;

  .cb-badge {
    print-color-adjust:exact !important;
  }

  &__title {
    @include h4-regular($neutral5);
    margin-bottom: 32px;
  }

  .lo-structure-view {
    overflow: auto;
    height: 100%;
    width: 100%;
    margin-bottom: 43px;

    .lo-structure-view__content {
      overflow: auto;
    }

    .lo-structure-view__group {
      width: 100%;
    }
  }

  .cs-collapse--lvl-2 .ant-input-borderless[disabled] {
    background-color: $neutral0 !important;
  }

  .cb-button.item-type-select__button {
    &:disabled {
      background-color: transparent !important;
      svg {
        path {
          fill: $neutral5;
        }
      }
    }
  }
}
