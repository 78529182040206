@import "src/app/styles/mixins";


.project-version-select {
  width: 200px;

  .ant-select-selection-item {
    @include footnote();
    line-height: 32px !important;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__label {
    @include description-regular($neutral3);
  }
}
