@import "src/app/styles/mixins";

.course-structure {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1104px;
  overflow: hidden;
  height: calc(100vh - 120px - 32px - 36px );
  padding-bottom: 18px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
    padding-left: 24px;

    .block-title {
      margin-bottom: 0 ;
    }
  }

  &__loading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1104px;
    height: 484px;
    background-color: $white;
  }

  .cb-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    padding-bottom: 10px;
    @include scrollSmall();
    overflow: auto;

    [dir='rtl'] & {
      padding-right: 0;
      padding-left: 8px;
    }
  }
}
