@import "src/app/styles/variables";

.add-structure-elem.cs-element-view {
  .cs-element-view__title,
  .cs-element-view__description {
    &:hover {
      background-color: $neutral1;
    }
    &:focus {
      background-color: $neutral1;
    }
  }
}
