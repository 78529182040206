@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.more-action-drop,
.ant-dropdown-menu-submenu {
  .more-actions-label {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 8px 12px;
    column-gap: 8px;

    &__icon {
      display: flex;
      width: 12px;
      height: 12px;
    }

    &__text {
      @include description-regular($neutral5);
    }
    &--del {
      .more-actions-label__icon {
        color: $red3;
      }
      .more-actions-label__text {
        color: $red3;
      }
    }
  }

}
