@import "src/app/styles/variables";

.add-paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  padding-inline-start: 30px;

  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 80px;
    text-align: center;
    border: 1px dashed $neutral1;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      border-color: $neutral2;
    }
  }

  &__upload-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__wrap {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 32px;
  }

  .cb-text-area {
    border-color: transparent;

    &:focus,
    &:hover {
      border-color: $neutral5;
    }
  }
}
