@import "src/app/styles/mixins";

.modal-slides {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 100%;

  .cb-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    row-gap: 32px;
    margin: auto 0;

    &-tip {
      @include body-regular();
    }
  }

  &__list {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, 275px);
    overflow-y: auto;
    max-height: calc(100vh - 64px - 72px - 16px - 14px);
    @include scrollSmall();

    .files-upload {
      .ant-upload-drag {
        padding: 16px;
      }
    }
  }
}
