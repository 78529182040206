@import "src/app/styles/mixins";


.tokens-usage {
  max-width: 960px;
  &__title {
    @include h5-regular();
    margin-bottom: 16px;
  }
}
