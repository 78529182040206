@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.cs-collapse-index {
  display: flex;
  align-items: center;
  height: 24px;

  &__state {
    width: 6px;
    height: 6px;
    background-color: $green3;
    border-radius: 33px;

    &-wrap {
      width: 6px;
      height: 6px;
      margin-right: 4px;

      [dir='rtl'] & {
        margin-left: 4px;
        margin-right: 0;
      }
    }
  }

  &__num {
    @include footnote($neutral3);
  }
}
