@import "src/app/styles/variables";
@import "src/app/styles/mixins";


.switch-content-lang {

  &-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;

    &__dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $green3;
    }
  }
}
