@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.tour {
  &__title {
    @include body-regular($neutral5);
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__text {
    @include description-regular($neutral4);
  }
}
