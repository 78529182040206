@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.export-params-form {
  display: flex;
  flex-direction: column;

  .cb-select {
    width: 250px;
  }

  &-opt__wrap {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }

  &-opt__label {
    @include description-regular($neutral5);
    line-height: 14px !important;
  }



  .ant-select-selection-item {
    display: inline-flex;
    align-items: center;
    line-height: inherit;
  }

  &__content {
    margin-top: 8px;
    padding: 0;
    max-width: 600px;
  }
}

.ant-select-item-option-content {
  display: inline-flex !important;
  align-items: center !important;
}

.ant-select-item-option-disabled {
  color: $neutral2;
  .export-params-form-opt__label {
    color: $neutral2;
  }

}
